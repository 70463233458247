@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@300&display=swap');

$m-violet: #353289;
$s-violet: #c3c0d9 ;

$family-sans-serif: 'Noto Sans SC', sans-serif;
$link: $s-violet;
$primary: $m-violet;

@import "../node_modules/bulma/bulma.sass";
